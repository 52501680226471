import React from 'react'
import Header from './header'
import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'
import CvBuilderSidebar from './sidebar/cv-builder-sidebar'
// import AppSidebar from './sidebar/app-sidebar'
import { HeaderMenus } from '../../../../utils/menu-items'

function Layout () {
    localStorage.setItem('resume-editing', 'false')
    return (
        <div className={'max-w-screen-2xl mx-auto'}>
            <Header menuItems={HeaderMenus}/>
            <div className={'flex gap-x-2.5 mx-2 md:mx-8 mt-[18px]'}>
                <div className={'hidden md:block border-r border-gray-200'}>
                    <CvBuilderSidebar/>
                    {/* <AppSidebar/> */}
                </div>
                <div className={'w-full mb-5'}>
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.any
}

export default Layout
